@tailwind base;
@tailwind components;
@tailwind utilities;

.thin-scrollbar-x {
  scrollbar-width: thin; /* Firefox */
}

.thin-scrollbar-x::-webkit-scrollbar {
  height: 1px; /* Set the height of the scrollbar */
}

.thin-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px; /* Optional: rounded scrollbar */
}

.thin-scrollbar-x::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}
